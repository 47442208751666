import { z } from "zod";

// Define supported image MIME types
const IMAGE_MIME_TYPES = ["image/jpeg", "image/png", "image/gif", "image/webp"];

export const imageUploadSchema = z.object({
  files: z
    .array(
      z.instanceof(File).refine((file) => IMAGE_MIME_TYPES.includes(file.type), {
        message: "Vain kuvat (JPEG, PNG, GIF, WebP) ovat sallittuja.",
      })
    )
    .max(5, "Voit lisätä 5 kuvaa. Poista vähintään yksi lisätäksesi uuden."),
});