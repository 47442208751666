'use client'
import React from 'react'
import { useState, ChangeEvent, FocusEvent } from 'react';
import { Container, FormControl, FormLabel, NumberInput, NumberInputField, Select, Image, Text, Box,  Input, HStack, Textarea, Button, FormErrorMessage, useToast, IconButton } from "@chakra-ui/react";
import { DeleteIcon } from '@chakra-ui/icons';
import { z } from 'zod';
import { imageUploadSchema } from '../validation/contactFormSchema';

interface ContactFormValues {
    name: string;
    email: string;
    phone: string;
    pairs: string;
    metod: string;
    payment: string;
    images: File[];
    info: string;
}

interface ContactFormState {
    values: ContactFormValues;
    isLoading: boolean;
    error: string;
}

//Initial values for the form
const initValues: ContactFormValues = { 
    name: "",
    email: "",
    phone: "",
    pairs: "1",
    metod: 'tuonti',
    payment: 'kortti',
    images: [],
    info: '',
}

//Initialy none of the vlaues are touched
const initTouches = { 
    name: false,
    email: false,
}

//wraps initial values to maintain the structure
const initState: ContactFormState = {
    values: initValues,
    isLoading: false,
    error: '',
}

//Schema for validation
const validator = z.string().email();

export default function ContactForm() {
    const toast = useToast()
    const [state, setState] = useState(initState);
    const [touched, setTouched] = useState(initTouches)
    const {values, isLoading, error} = state;

    const [dragActive, setDragActive] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

    // Handles file selection via input or drag-and-drop
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const filesArray = Array.from(event.target.files);
            const newFiles = [...selectedFiles, ...filesArray]
            // Validate with Zod
            const result = imageUploadSchema.safeParse({ files: newFiles });
            if (!result.success) {
                alert(`${result.error.errors[0].message}`)
                return;
            }
            setSelectedFiles(newFiles);
            setState((prev) => ({
                ...prev,
                values: {
                    ...prev.values,
                    images: newFiles,
                },
            }));
        }
    };

    // Handles drag-over effect
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragActive(true);
    };

    // Handles drag leave
    const handleDragLeave = () => {
        setDragActive(false);
    };

    // Handles dropping files
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragActive(false);

        if (event.dataTransfer.files) {
            const filesArray = Array.from(event.dataTransfer.files);
            const newFiles = [...selectedFiles, ...filesArray]
            const result = imageUploadSchema.safeParse({ files: newFiles });
            if (!result.success) {
                alert(`${result.error.errors[0].message}`)
                return;
            }
            setSelectedFiles(newFiles);
            setState((prev) => ({
                ...prev,
                values: {
                    ...prev.values,
                    images: newFiles,
                },
            }));
        }
        console.log('current images: ', state.values.images)
    };

    // handles removing an image
    const handleRemoveImage = (indexToRemove: number) => {
        const newFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
        
        setSelectedFiles(newFiles);
        setState((prev) => ({
            ...prev,
            values: {
                ...prev.values,
                images: newFiles,
            },
        }));
    };

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
        const { target } = event;
        setTouched((prev) => ({
            ...prev,
            [target.name]: true,
        }));
    };

    //handles change for input fields, updating the state
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setState((prev) => ({
            ...prev,
            values: {
                ...prev.values,
                [target.name]: target.value,
            }
        }));

    }

    //handle select change
    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const { target } = event;
        setState(prev => ({
            ...prev,
            values: {
                ...prev.values,
                [target.name]: target.value
            }
        }));
    
    };

    //handles change for input fields, updating the state
    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { target } = event;
        setState((prev) => ({
            ...prev,
            values: {
                ...prev.values,
                [target.name]: target.value,
            }
        }));
    };


    const onSubmit = async () => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        
        try {
            // Create FormData to handle text and files
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("phone", values.phone);
            formData.append("pairs", values.pairs);
            formData.append("metod", values.metod);
            formData.append("payment", values.payment);
            formData.append("info", values.info);

            // Append each selected file
            selectedFiles.forEach((file, index) => {
                formData.append(`files`, file); // `files` is the key for multiple files
            });
            const response = await fetch('/api/contacts', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            
            if (!response.ok) {
                throw new Error(data.error || 'Something went wrong');
            }

            setState(initState);
            setTouched(initTouches);
            setSelectedFiles([])
            toast({
                title: "Lomake lähetetty.",
                description: "Olemme sinuun pian yhteydessä sähköpostitse.",
                status: "success",
                duration: 5000,
                position: "top",
            });
        } catch (error: any) {
            setState((prev) => ({
                ...prev,
                isLoading: false,
                error: error.message,
            }));
        } finally {
            setState(prev => ({ ...prev, isLoading: false }));
        }
    }



  return (
    <section className='formSection'>
        <div className='pt-24 pb-24 '>
            <Container id="contact" className='formContainer' height="100%" width='100%' maxW='800px' p={10} >
                <h3 className='form-heading'>KENGISTÄSI CLEANIT</h3 >
                {error && (
                    <Text color="red.300" my={4} fontSize="xl">
                        {error}
                    </Text>
                )}
                <FormControl isRequired isInvalid={touched.name && !values.name} color='#2B3A67' pt='15px'>
                    <FormLabel fontFamily='subHeadingFont' mb='0'>Nimi</FormLabel>
                    <Input
                        type='text'
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={onBlur}
                        border='solid 1px #B4C4D9'
                        backgroundColor='#FFFFFF'
                        _hover={{
                            border: '2px solid #6AD4FF',
                        }}
                        _invalid={{
                            border: '2px solid #C84B31'
                        }}
                       
                    />
                    <FormErrorMessage color='#C84B31'>Pakollinen kenttä</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={(touched.email && !values.email) || (touched.email && !validator.safeParse(values.email).success)} color='#2B3A67' pt='15px'>
                    <FormLabel fontFamily='subHeadingFont' mb='0'>Sähköposti</FormLabel>
                    <Input
                        type='email'
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={onBlur}
                        border='solid 1px #B4C4D9'
                        backgroundColor='#FFFFFF'
                        _hover={{
                            border: '2px solid #6AD4FF',
                        }}
                        _invalid={{
                            border: '2px solid #C84B31'
                        }}
                    />
                    <FormErrorMessage color='#C84B31'>Syötä kelvollinen sähköposti</FormErrorMessage>
                </FormControl>
                <FormControl color='#2B3A67' pt='15px'>
                    <FormLabel fontFamily='subHeadingFont' mb='0' >Puhelin</FormLabel>
                    <Input
                        type='tel'
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        border='solid 1px #B4C4D9'
                        backgroundColor='#FFFFFF'
                        _hover={{
                            border: '2px solid #6AD4FF',
                        }}
                        _invalid={{
                            border: '2px solid #C84B31'
                        }}
                    />
                </FormControl>
                <FormControl color='#2B3A67' pt='15px'>
                    <FormLabel fontFamily='subHeadingFont' mb='0' >Parien määrä</FormLabel>
                    <NumberInput min={1} defaultValue={1}>
                        <NumberInputField 
                            placeholder="Kuinka monta paria tuot?" 
                            name='pairs'
                            value={values.pairs}
                            onChange={handleChange}
                            border='solid 1px #B4C4D9'
                            backgroundColor='#FFFFFF'
                            _hover={{
                                border: '2px solid #6AD4FF',
                            }}
                            _invalid={{
                                border: '2px solid #C84B31'
                            }}
                        />
                    </NumberInput>
                    <FormErrorMessage color='#C84B31'>Syötä numero</FormErrorMessage>
                </FormControl>
                <FormControl color='#2B3A67' pt='15px'>
                    <FormLabel fontFamily='subHeadingFont' mb='0'>Valitse toimintatapa</FormLabel>
                    <Select 
                        
                        name='metod'
                        value={values.metod}
                        onChange={handleSelectChange} 
                        border='solid 1px #B4C4D9'
                        backgroundColor='#FFFFFF'
                        _hover={{
                            border: '2px solid #6AD4FF',
                        }}
                        >
                        <option value="tuonti">Tuonti Lauttasaareen</option>
                        <option value="postitus">Postitus</option>
                    </Select>
                </FormControl>
                <FormControl color='#2B3A67' pt='15px' mb={6}>
                    <FormLabel fontFamily='subHeadingFont' mb='0'>Valitse maksutapa</FormLabel>
                    <Select 
                        name='payment'
                        value={values.payment}
                        onChange={handleSelectChange} 
                        border='solid 1px #B4C4D9'
                        backgroundColor='#FFFFFF'
                        _hover={{
                            border: '2px solid #6AD4FF',
                        }}
                        >
                        <option value="Korttimaksu">Korttimaksu</option>
                        <option value="Käteinen">Käteinen</option>
                        <option value="Laksu">Lasku sähköpostiin</option>
                    </Select>
                </FormControl>
                <FormControl color='#2B3A67' pt='15px' mb={6}>
                    <FormLabel fontFamily='subHeadingFont' mb='0'>Lisää kuvia kengistäsi</FormLabel>
                    
                    {/* Drag-and-Drop Box */}
                    <Box
                        border='2px dashed #B4C4D9'
                        backgroundColor={dragActive ? '#E6F7FF' : '#FFFFFF'}
                        padding='20px'
                        textAlign='center'
                        cursor='pointer'
                        _hover={{ border: '2px solid #6AD4FF' }}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <Text fontSize='sm' color='#2B3A67'>
                            Drag and drop images here or click to select
                        </Text>
                        <Input
                            type='file'
                            name='images'
                            multiple
                            accept='image/*'
                            display='none'
                            onChange={handleImageUpload}
                            id='fileInput'
                        />
                        <label htmlFor="fileInput">
                            <Text as="span" color="blue.500" cursor="pointer">
                                Click to Upload
                            </Text>
                        </label>
                        {selectedFiles.length > 0 && (
                            <HStack mt={3} className='justify-center'>
                                {selectedFiles.map((file, index) => (
                                    <Box key={index}>
                                        <IconButton
                                            icon={<DeleteIcon />}
                                            size="xs"
                                            position="relative"
                                            top="10px"
                                            right="-50%"
                                            bg="red.500"
                                            color="white"
                                            borderRadius="full"
                                            _hover={{ bg: "red.700" }}
                                            onClick={() => handleRemoveImage(index)}
                                            aria-label="Poista kuva"
                                        />
                                        <Image src={URL.createObjectURL(file)} alt="Preview" boxSize="75px" />
                                    </Box>
                                ))}
                            </HStack>
                        )}
                    </Box>

                    
                </FormControl>
                <FormControl mt={4}>
                    <FormLabel fontFamily='subHeadingFont' color={'#2B3A67'}>Lisätietoa</FormLabel>
                    <Textarea 
                        name='info'
                        placeholder='Minkälaista palvelua kengillesi haluat?'
                        fontSize={{ base: '10px', sm:'14px', md:'16px'}}
                        rows={4}
                        value={values.info}
                        onChange={handleTextChange}
                        color='#1C2972'
                        border='solid 1px #B4C4D9'
                        backgroundColor='#FFFFFF'
                        _hover={{
                            border: '2px solid #6AD4FF',
                        }}
                    />
                </FormControl>
                <Button
                    loadingText="Lähettää"
                    isLoading={isLoading}
                    bg="#F2A900"
                    color="#2B3A67" /* Ensure good contrast */
                    _hover={{ bg: "#D28A00" }} /* Darker gold on hover */
                    _disabled={{ bg: "#B4C4D9", color: "#FFFFFF", cursor: "not-allowed" }} /* Greyish when disabled */
                    variant="solid"
                    isDisabled={!values.name || !values.email || !values.metod || !values.payment || !validator.safeParse(values.email).success}
                    onClick={onSubmit}
                    marginTop="15px"
                    justifyContent="center"
                    width="100%"
                >
                    Lähetä
                </Button>
            </Container>
        </div>

    </section>
  )
}
