'use client'
import React from 'react'
import { motion } from 'framer-motion';
import Image from 'next/image';

export default function Landing() {

  return (
    <section
        id="landing"
        className='w-full h-screen overflow-hidden relative'
    >
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
        <Image
          src="/images/landing.jpeg"
          alt="Landing"
          layout="fill"
          objectFit="cover"
          priority
        />
      </div>
      <div className='landing-content absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] z-1 flex items-center justify-around w-full pl-[5%] pr-[5%]'>
          <div className='flex flex-col gap-10 max-w-[500px]'>
            <h1>SNEAKER LAUNDRY</h1>
            <h2>Kenkäpesu, entisöinti ja kustomointi Helsingissä</h2>
            <a href="#process">
              <motion.button
                className='text-background border-2 p-1 border-solid rounded-lg w-[40%] landing-button  hover:text-cleanit_blue hover:border-cleanit_blue'
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Miten pesu toimii?
              </motion.button>
            </a>
            
          </div>
          <div className='flex flex-col gap-10 max-w-[500px]'>
            <h2 className='text-cleanit_blue font-semibold'>Nouto ja kotiinkuljetus Helsingin ja Espoon alueella</h2>
            <h2 className='text-accent font-bold'>Ilmainen takaisin postitus yli 3 parin posteihin</h2>
            <a href="#contact">
              <motion.button
                className='text-background border-2 p-1  border-solid rounded-lg w-[40%] landing-button hover:text-accent hover:border-accent'
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Kengistäsi cleanit
              </motion.button>
            </a>
            
          </div>
        </div>
    </section>
  )
}
